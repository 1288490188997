"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerService = exports.ServerEvents = void 0;
const colyseus_js_1 = require("colyseus.js");
var ServerEvents;
(function (ServerEvents) {
    ServerEvents["OnStateChange"] = "onstatechange";
    ServerEvents["OnMessage"] = "onmessage";
})(ServerEvents || (exports.ServerEvents = ServerEvents = {}));
class ServerService {
    constructor(serverUrl) {
        this.serverHandlers = new Map();
        this.isTryingToReconnect = false;
        this.msgs = { SendMessage: 0 };
        this.client = new colyseus_js_1.Client(serverUrl);
        this.serverUrl = serverUrl;
        this.msgs.SendMessage;
    }
    get SessionID() {
        return this.room.sessionId;
    }
    get RoomId() {
        return this.room.roomId;
    }
    get isConnected() {
        return this.room.connection.isOpen;
    }
    get canReconnect() {
        return this.reconnectionToken != null;
    }
    get state() {
        return this.room.state;
    }
    connect(roomName, options) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.room = !(options === null || options === void 0 ? void 0 : options.roomId)
                    ? yield this.client.joinOrCreate(roomName, options)
                    : yield this.client.joinById(options.roomId, options);
                console.log(this.room.sessionId, "joined", this.room.name, "roomId", this.room.roomId);
                //store reconnection details
                this.reconnectionToken = this.room.reconnectionToken;
                console.log('reconnectionToken=' + this.reconnectionToken);
                //add listeners to room
                this.configureRoomListeners(this.room, this.msgs);
                this.isTryingToReconnect = true;
                this.listenAndReconnect();
                return this.room;
            }
            catch (e) {
                console.error("JOIN ERROR", e);
                throw new Error("Exception while joining room");
            }
        });
    }
    reconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.room) {
                console.log("closing connection");
                this.disconnect();
            }
            try {
                this.client = new colyseus_js_1.Client(this.serverUrl);
                this.room = yield this.client.reconnect(this.reconnectionToken);
                console.log(this.room.sessionId, "reconnected", this.room.name, "roomId", this.room.roomId);
                //store reconnection details
                this.reconnectionToken = this.room.reconnectionToken;
                console.log('reconnectionToken=' + this.reconnectionToken);
                //add listeners to room
                this.configureRoomListeners(this.room, this.msgs);
                return this.room;
            }
            catch (e) {
                console.error("Unable to reconnect to room", e);
            }
        });
    }
    disconnect() {
        console.log('disconnecting from room');
        this.room.connection.close();
    }
    leave() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isTryingToReconnect = false;
            yield this.room.leave(true);
        });
    }
    configureRoomListeners(room, msgs) {
        room.onMessage(msgs.SendMessage, (data) => {
            const handler = this.serverHandlers.get(data.type);
            if (handler) {
                handler(data);
            }
        });
        room.onStateChange(() => {
            const handler = this.serverHandlers.get(-1);
            if (handler) {
                handler(this.room);
            }
        });
    }
    listenAndReconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            //if not connected, try to reconnect, 
            //check again in 1 second
            setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                if (this.isTryingToReconnect) {
                    if (!this.room.connection.isOpen) {
                        yield this.reconnect();
                    }
                    this.listenAndReconnect();
                }
            }), 1000);
        });
    }
    send(msgType, data) {
        this.room.send(this.msgs.SendMessage, Object.assign({ type: msgType }, data));
    }
    on(msgType, cb, context) {
        this.serverHandlers.set(msgType, cb);
    }
    onRoomStateChange(cb, context) {
        this.serverHandlers.set(-1, cb);
    }
}
exports.ServerService = ServerService;
